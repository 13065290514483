import DefaultInfo from "./_default"
import {
  newExerciseWithPointsPerAnswer,
  newExerciseWithPointsPerIncorrectAnswer,
  newExerciseWithTime,
} from "./common/exercise"
import { answerWithFeedback } from "./common/answer"
import FIELD_TYPES from "../fieldTypes"

class CleaningInfo extends DefaultInfo {
  static exercise = {
    ...DefaultInfo.exercise,
    parameters: [
      ...newExerciseWithTime,
      ...newExerciseWithPointsPerAnswer,
      ...newExerciseWithPointsPerIncorrectAnswer,
      {
        id: "availableTries",
        type: FIELD_TYPES.number,
        description: "Maksymalna liczba błędnych odpowiedzi",
        default: 3,
        inline: true,
      },
    ],
  }

  static question = {
    ...DefaultInfo.question,
    parameters: [
      {
        id: "feedback",
        type: FIELD_TYPES.text,
        description: "Informacja zwrotna",
        placeholder:
          "Wpisz informację, która pojawia się po zaznaczeniu wszystkich dobrych odpowiedzi",
        default: "",
      },
    ],
  }

  static answer = {
    ...DefaultInfo.answer,
    parameters: [...DefaultInfo.answer.parameters, ...answerWithFeedback],
  }
}

export default CleaningInfo
