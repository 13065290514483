import DefaultInfo from "./_default";
import {
  exerciseWithPointsPerAnswer,
  newExerciseWithTime,
} from "./common/exercise";
import { answerWithFeedback } from "./common/answer";
import FIELD_TYPES from "../fieldTypes";

class PuzzleInfo extends DefaultInfo {
  static exercise = {
    ...DefaultInfo.exercise,
    parameters: [...newExerciseWithTime, ...exerciseWithPointsPerAnswer],
  };

  static question = {
    ...DefaultInfo.question,
    parameters: [
      {
        id: "image",
        description: "Zdjęcie",
        placeholder: "Podaj adres zdjęcia (/images/...)",
        type: FIELD_TYPES.string,
        default: "/images/",
      },
    ],
  };

  static answer = {
    ...DefaultInfo.answer,
    parameters: [...DefaultInfo.answer.parameters, ...answerWithFeedback],
  };
}

export default PuzzleInfo;
