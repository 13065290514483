import Global from "./global"
import Project from "./project"
import axiosRequest from "../utilities/axiosRequest"

export default class Session {
  static TYPES = {
    SESSION_SELECTED: "SESSION_SELECTED",
    EXERCISE_LIST_CHANGED: "EXERCISE_LIST_CHANGED",
    SESSION_CHANGED: "SESSION_CHANGED",

    SPRINT_COPIED: "SPRINT_COPIED",
  }

  static selectSessionById(id) {
    return {
      type: Session.TYPES.SESSION_SELECTED,
      id,
    }
  }

  static exerciseListChanged(exercisesList) {
    return {
      type: Session.TYPES.EXERCISE_LIST_CHANGED,
      exercisesList,
    }
  }

  static sessionChanged(session) {
    return {
      type: Session.TYPES.SESSION_CHANGED,
      session,
    }
  }

  static sprintCopied(sprintId, sprintName) {
    return {
      type: Session.TYPES.SPRINT_COPIED,
      sprintId,
      sprintName,
    }
  }

  static reorderExercisesList(exercisesOrder) {
    return sessionRequestWithSessionListChanges(
      "Zmieniam kolejność sprintów",
      "Nie mogę zapisać kolejności sprintów.",
      "Wystąpił problem podczas zapisywania kolejności sprintów.",
      {
        method: "POST",
        url: `reorder`,
        data: {
          exercisesOrder,
        },
      }
    )
  }

  static updateCurrentSession(values) {
    return sessionRequestWithSessionListChanges(
      "Modyfikuję sprint",
      "Nie mogę zmodyfikować sprintu.",
      "Wystąpił błąd podczas modyfikacji sprintu.",
      {
        method: "PUT",
        url: ``,
        data: {
          session: values,
        },
      },
      {
        shouldUpdateSessionsList: true,
      }
    )
  }

  static loadExercisesList() {
    return sessionRequestWithSessionListChanges(
      "Wczytuję listę ćwiczeń",
      "Nie mogę wczytać listy ćwiczeń.",
      "Wystąpił błąd podczas wczytywania listy ćwiczeń.",
      {
        method: "GET",
        url: `exercises`,
      }
    )
  }

  static addExercise(exercise) {
    return sessionRequestWithSessionListChanges(
      "Dodaję ćwiczenie",
      "Nie mogę dodać ćwiczenia.",
      "Wystąpił błąd podczas dodawania ćwiczenia.",
      {
        method: "POST",
        url: `add-exercise`,
        data: {
          exerciseType: exercise.type,
        },
      }
    )
  }

  static removeExercise(exerciseId) {
    return sessionRequestWithSessionListChanges(
      "Usuwam ćwiczenie",
      "Nie mogę usunąć ćwiczenia.",
      "Wystąpił błąd podczas usuwania ćwiczenia.",
      {
        method: "POST",
        url: `remove-exercise`,
        data: {
          exerciseId: exerciseId,
        },
      }
    )
  }

  static copySprintToProject(projectId) {
    return sessionRequestWithSessionListChanges(
      "Kopiuję sprint",
      "Nie mogę skopiować sprintu.",
      "Wystąpił błąd podczas kopiowania sprintu.",
      {
        method: "POST",
        url: `copy-to-project/${projectId}`,
      },
      {
        shouldUpdateSessionsList: true,
        useCopiedSprintId: true,
      }
    )
  }
}

function sessionRequestWithSessionListChanges(
  processingMessage,
  errorMessage,
  requestErrorMessage,
  requestOptions,
  options = {
    shouldUpdateSessionsList: false,
    useCopiedSprintId: false,
  }
) {
  return async (dispatch, getState) => {
    const { useCopiedSprintId, shouldUpdateSessionsList } = options
    const { session } = getState()

    let sessionId
    if (useCopiedSprintId) {
      sessionId = session.copiedSprint.id
    } else {
      sessionId = session.currentSessionId
    }

    const closingSlash = requestOptions["url"] ? "/" : ""

    requestOptions["url"] = `admin/session/${sessionId}` + closingSlash + requestOptions["url"]

    try {
      dispatch(Global.startProcessing(processingMessage))
      const { data, status } = await axiosRequest(requestOptions, dispatch)
      if (status !== 200) {
        dispatch(Global.errorOccurred(errorMessage))
      } else {
        if (shouldUpdateSessionsList) dispatch(Project.loadSprintsList())
        const exercisesList = data["exercises"]
        const session = data["session"]
        if (exercisesList) dispatch(Session.exerciseListChanged(exercisesList))
        if (session) dispatch(Session.sessionChanged(session))
        dispatch(Global.finishProcessing())
      }
    } catch (error) {
      console.error(error)
      dispatch(Global.errorOccurred(requestErrorMessage))
    }
  }
}
