import DefaultInfo from "./_default"
import { exerciseWithTime } from "./common/exercise"
import FIELD_TYPES from "../fieldTypes"
import { answerWithFeedback } from "./common/answer"

class RevealInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...exerciseWithTime,
      {
        id: "showHints",
        description: "Pokaż podpowiedź",
        type: FIELD_TYPES.bool,
        default: true,

        inline: true,
        small: true,
      },
      {
        id: "shouldBlurAnswers",
        description: "Zasłaniaj odpowiedzi",
        type: FIELD_TYPES.bool,
        default: true,

        inline: true,
        small: true,
      },
    ],
    modules: [],
  }

  static question = {
    ...DefaultInfo.question,
    parameters: [
      {
        id: "feedback",
        type: FIELD_TYPES.text,
        description: "Informacja zwrotna",
        placeholder:
          "Wpisz informację, która pojawia się po zaznaczeniu wszystkich dobrych odpowiedzi",
        default: "",
      },
    ],
  }

  static answer = {
    ...DefaultInfo.answer,
    parameters: [...answerWithFeedback],
  }
}

export default RevealInfo
