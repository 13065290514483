import Auth from "../actions/auth"

export default function axiosRequest(options, dispatch) {
  const username = window.localStorage.getItem("username")
  const token = window.localStorage.getItem("token")

  if (!("headers" in options)) {
    options["headers"] = {}
  }

  options.headers["Authorization"] = `${username}###${token}`
  // options.headers["X-UserToken"] = token

  return window.axios(options).catch(({ response: { data, status } }) => {
    if (status === 401) {
      dispatch(Auth.logout())
    }
    return { data, status }
  })
}
