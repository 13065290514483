import DefaultInfo from "./_default";
import {
  exerciseWithFeedback,
  exerciseWithLearning,
  exerciseWithPointsPerAnswer,
  exerciseWithTime
} from "./common/exercise";
import {answerWithFeedback} from "./common/answer";
import FIELD_TYPES from "../fieldTypes";


class CauldronInfo extends DefaultInfo {
  static exercise = {
    ...DefaultInfo.exercise,
    parameters: [
      ...DefaultInfo.exercise.parameters,
      ...exerciseWithTime,
      ...exerciseWithPointsPerAnswer,
      {
        id: "pointsPerIncorrectAnswer",
        type: FIELD_TYPES.number,
        description: "Ile punktów tracimy za nieprawidłową odpowiedź?",
        placeholder: "liczba punktów",
        default: 1,
        inline: true,
        small: false,
      },
      {
        id: "availableTries",
        type: FIELD_TYPES.number,
        description: "Liczba niepoprawnych odpowiedzi, która kończy pytanie",
        placeholder: "liczba pytań",
        default: 3,
        inline: true,
        small: false,
      },
      ...exerciseWithFeedback,
      ...exerciseWithLearning,
    ],
  };

  static question = {
    ...DefaultInfo.question,
  };

  static answer = {
    ...DefaultInfo.answer,
    parameters: [
      ...DefaultInfo.answer.parameters,
      ...answerWithFeedback,
    ]
  };

  static newQuestion = () => (
    {
      ...DefaultInfo.newQuestion(),
    }
  );

  static newAnswer = () => (
    {
      ...DefaultInfo.newAnswer(),
    }
  );
}

export default CauldronInfo;