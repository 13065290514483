import React from "react"
import { Card } from "@blueprintjs/core"
import { defaultTo, get } from "lodash"

import Input from "components/ui/inputs/Input"

import "./ExerciseParameters.scss"
import ModuleInfoFactory from "../../../exercises/ModuleInfoFactory"

export default function ExerciseParameters({
  exerciseInfo,
  exerciseParameters,
  setExerciseParameter$,
}) {
  const parameters = exerciseInfo.parameters
  const modules = defaultTo(exerciseInfo.modules, [])
    .map((type) => ModuleInfoFactory.getInfo(type).exerciseParameters)
    .flat()
  const otherValues = {
    exercise: exerciseParameters,
  }

  const visible = parameters.length + modules.length > 0

  if (visible) {
    return (
      <Card className="ExerciseParameters">
        {parameters.length > 0 &&
          parameters.concat(modules).map((info, index) => {
            return (
              <Input
                key={index}
                info={info}
                value={get(exerciseParameters, info.id)}
                setValue={setExerciseParameter$(info.id)}
                otherValues={otherValues}
              />
            )
          })}
      </Card>
    )
  } else {
    return null
  }
}
