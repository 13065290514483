import React, { Component } from "react"
import PropTypes from "prop-types"
import { CSSTransition } from "react-transition-group"

import "./css/Animation.scss"
const ANIMATION_SPEED = 300

export default class Animation extends Component {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    active: PropTypes.bool,
    delay_ms: PropTypes.number,
  }

  static defaultProps = {
    type: "fade",
    active: true,
    delay_ms: 0,
  }

  timeout

  constructor(props) {
    super(props)

    this.state = {
      show: false,
    }
  }

  static TYPES = {
    fade: "fade",
  }

  componentDidMount() {
    this.timeout = setTimeout(this.updateVisibility, this.props.delay_ms, this.props.active)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      clearTimeout(this.timeout)
      if (this.props.active) {
        this.timeout = setTimeout(this.updateVisibility, this.props.delay_ms, this.props.active)
      } else {
        this.updateVisibility(this.props.active)
      }
    } else if (prevProps.id !== this.props.id && this.props.active) {
      clearTimeout(this.timeout)
      this.updateVisibility(false)
      this.timeout = setTimeout(this.updateVisibility, this.props.delay_ms, this.props.active)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  updateVisibility = (visible) => {
    this.setState({ show: visible })
  }

  render() {
    return (
      <CSSTransition
        in={this.state.show}
        classNames={this.props.type}
        timeout={ANIMATION_SPEED}
        mountOnEnter
        appear
      >
        <div className={this.props.className}>{this.props.children}</div>
      </CSSTransition>
    )
  }
}
