import DefaultInfo from "./_default"
import FIELD_TYPES from "../fieldTypes"
import { answerWithFeedback } from "./common/answer"

class ConversationsInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...DefaultInfo.exercise.parameters,
      {
        id: "points.max",
        type: FIELD_TYPES.number,
        description: "Maksymalna liczba punktów do zdobycia",
        default: 4,
        inline: true,
        small: false,
      },
    ],
  }

  static question = {
    ...DefaultInfo.question,
    parameters: [
      {
        id: "image",
        description: "Zdjęcie",
        placeholder: "Podaj adres zdjęcia (/images/...)",
        type: FIELD_TYPES.string,
        default: "",
      },
    ],
  }

  static answer = {
    ...DefaultInfo.answer,
    parameters: [
      ...answerWithFeedback,
      {
        id: "nextQuestionIndex",
        type: FIELD_TYPES.number,
        description: "Następne pytanie (indeks)",
        placeholder: "",
        default: 0,
        inline: true,
        small: false,
      },
      {
        id: "points",
        type: FIELD_TYPES.number,
        description: "Liczba punktów za poprawną odpowiedź",
        placeholder: "",
        default: 2,
        inline: true,
        small: false,
      },
    ],
  }

  static newQuestion = () => ({
    content: "",
    parameters: {},
    answers: [this.newAnswer(), this.newAnswer(), this.newAnswer()],
  })

  static newAnswer = (correct = false) => ({
    content: "",
    correct,
    parameters: {},
  })
}

export default ConversationsInfo
