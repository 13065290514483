import DefaultInfo from "./_default"
import { newExerciseWithPointsPerAnswer, newExerciseWithTime } from "./common/exercise"
import { answerWithCorrectIncorrectFeedback } from "./common/answer"
import FIELD_TYPES from "../fieldTypes"

class TrueFalseInfo extends DefaultInfo {
  static exercise = {
    parameters: [...newExerciseWithTime, ...newExerciseWithPointsPerAnswer],
    modules: [],
  }

  static question = {
    ...DefaultInfo.question,
    parameters: [
      {
        id: "trueName",
        description: "Treść przycisku 'Prawda'",
        placeholder: "",
        type: FIELD_TYPES.string,
        default: "",
      },
      {
        id: "falseName",
        description: "Treść przycisku 'Fałsz'",
        placeholder: "",
        type: FIELD_TYPES.string,
        default: "",
      },
    ],
  }

  static answer = {
    ...DefaultInfo.answer,
    parameters: [
      ...answerWithCorrectIncorrectFeedback,
      {
        id: "image",
        description: "Zdjęcie",
        placeholder: "Podaj adres zdjęcia (/images/...)",
        type: FIELD_TYPES.string,
        default: "",
      },
      {
        id: "category",
        description: "Kategoria",
        placeholder: "",
        type: FIELD_TYPES.string,
        default: "",
      },
    ],
    modules: [
      // "answer_categories"
    ],
  }

  static newQuestion = () => ({
    content: "",
    parameters: {},
    answers: [this.newAnswer(), this.newAnswer(), this.newAnswer(), this.newAnswer()],
  })

  static newAnswer = (correct = false) => ({
    content: "",
    correct,
    parameters: {},
  })
}

export default TrueFalseInfo
