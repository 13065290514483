import React, { Component } from "react"
import PropTypes from "prop-types"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { set } from "lodash"

import reorder from "../../utilities/reorder"

import "../scss/Exercise.scss"
import Animation from "../shared/Animation"
import ReactRouteComponent from "../../mixins/ReactRouteComponentMixin"
import { Prompt, withRouter } from "react-router-dom"
import ExerciseParameters from "./subcomponents/ExerciseParameters"
import ExerciseActions from "../../actions/exercise"
import { connect } from "react-redux"
import ExerciseQuestion from "./subcomponents/ExerciseQuestion"
import ContentWithSidebar, { Content, ContentToolbar, Sidebar } from "../layout/ContentWithSidebar"
import { ButtonGroup, Button, Intent, EditableText, H1 } from "@blueprintjs/core"
import MyClasses from "../../shared/MyClasses"
import { IconNames } from "@blueprintjs/icons"
import ButtonLink from "../ui/ButtonLink"
import InfoFactory from "../../exercises/ExerciseInfoFactory"
import { AdminPaths } from "../Admin/Admin"

/**
 * @mixes ReactRouteComponent
 */
class Exercise extends ReactRouteComponent(Component) {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),

    loaded: PropTypes.bool,

    id: PropTypes.number,
    sessionId: PropTypes.number,
    instanceId: PropTypes.number,

    exercise: PropTypes.object,
    questions: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.state = {
      dataInitialised: false,
      dataChanged: false,
      dataSaving: false,

      innerName: props.exercise?.["name"],
    }
  }

  componentDidMount() {
    const id = this.getURLParameter("id")

    if (id !== undefined) {
      this.selectExerciseById(Number.parseInt(id))
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loaded) {
      this.loadExercise()
    }

    if (
      (!prevProps.exercise && this.props.exercise) ||
      (prevProps.exercise && prevProps.exercise?.name !== this.props.exercise?.name)
    ) {
      this.setInnerName(this.props.exercise["name"])
    }
  }

  render() {
    const { dataChanged, innerName } = this.state
    const { loaded, id, sessionId, instanceId, exercise, questions } = this.props

    if (!loaded) {
      return ""
    } else {
      const info = InfoFactory.getInfo(exercise["slug"])
      const exerciseInfo = InfoFactory.getExerciseInfo(exercise["slug"])

      return (
        <ContentWithSidebar>
          <ContentToolbar>
            <ButtonLink
              minimal
              to={AdminPaths.openSession(sessionId)}
              icon={IconNames.CIRCLE_ARROW_LEFT}
              text="Wróć do sprintu"
              disabled={sessionId === undefined}
            />
          </ContentToolbar>
          <Content className="Exercise">
            <Prompt
              when={dataChanged}
              message={() => `Czy na pewno chcesz porzucić niezapisane zmiany?`}
            />
            <Animation type="fade" active={loaded}>
              <H1 id="exercise-name">
                <EditableText
                  placeholder="Nazwa ćwiczenia"
                  value={innerName}
                  onChange={this.setInnerName}
                  onConfirm={this.confirmInnerName}
                />
              </H1>
              <ExerciseParameters
                exerciseInfo={exerciseInfo}
                exerciseParameters={exercise.parameters}
                setExerciseParameter$={this.changeExerciseParameter$}
              />
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable-questions" direction="vertical" type="questions">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {questions.map((question, questionIndex) => (
                        <Draggable
                          index={questionIndex}
                          key={questionIndex}
                          draggableId={`question-${questionIndex}`}
                        >
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <ExerciseQuestion
                                dragHandleProps={provided.dragHandleProps}
                                exerciseType={exercise["slug"]}
                                exerciseParameters={exercise["parameters"]}
                                question={question}
                                questionIndex={questionIndex}
                                setQuestionField$={this.setQuestionWithIndexField$$(questionIndex)}
                                deleteQuestion={this.deleteQuestionWithIndex$(questionIndex)}
                                setQuestionParameter$={this.setQuestionWithIndexParameter$$(
                                  questionIndex
                                )}
                                setAnswerWithIndexField$$={this.setAnswerWithIndexFieldForQuestion$$$(
                                  questionIndex
                                )}
                                deleteAnswerWithIndex$={this.deleteAnswerWithIndexForQuestion$$(
                                  questionIndex
                                )}
                                setAnswerWithIndexParameter$$={this.setAnswerWithIndexParameterForQuestion$$$(
                                  questionIndex
                                )}
                                addAnswerForQuestion$={this.addAnswerForQuestion$}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Animation>
          </Content>
          <Sidebar>
            <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
              <Button
                large
                icon={IconNames.ADD}
                intent={Intent.PRIMARY}
                onClick={this.addQuestion}
                disabled={!info.canAddQuestion()}
                text="Dodaj pytanie"
              />
            </ButtonGroup>
            <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
              <Button
                large
                icon={IconNames.CLOUD_UPLOAD}
                intent={this.state.dataChanged ? Intent.DANGER : Intent.NONE}
                onClick={this.saveExercise}
                disabled={!this.state.dataChanged}
                text="Zapisz"
              />
            </ButtonGroup>
            <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
              <ButtonLink
                large
                icon={IconNames.CHART}
                to={`/report/${id}/${sessionId}/${instanceId}`}
                text="Raport dla ćwiczenia"
              />
            </ButtonGroup>
          </Sidebar>
        </ContentWithSidebar>
      )
    }
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    let questions = [...this.props.questions]

    if (result.type === "questions") {
      questions = reorder(questions, result.source.index, result.destination.index)
    } else {
      // One of answers
      const questionIndex = parseInt(result.type.slice(8))

      questions[questionIndex].answers = reorder(
        questions[questionIndex].answers,
        result.source.index,
        result.destination.index
      )
    }

    this.processChangeRequest(() => {
      this.changeExercise(undefined, questions)
    })
  }

  setInnerName = (value) => {
    this.setState({
      innerName: value,
    })
  }

  confirmInnerName = (value) => {
    this.changeExercise$("name")(value)
  }

  changeExercise$ = (field, subfield) => (value) => {
    const { exercise } = this.props

    this.processChangeRequest(() => {
      if (subfield)
        this.changeExercise({
          ...exercise,
          [field]: {
            ...exercise[field],
            [subfield]: value,
          },
        })
      else
        this.changeExercise({
          ...exercise,
          [field]: value,
        })
    })
  }

  changeExerciseParameter$ = (parameterName) => (value) => {
    const { exercise } = this.props

    this.processChangeRequest(() => {
      const newExercise = set(
        {
          ...exercise,
          parameters: {
            ...exercise.parameters,
          },
        },
        `parameters.${parameterName}`,
        value
      )
      console.log(newExercise)

      this.changeExercise(newExercise)
    })
  }

  setQuestionWithIndexField$$ = (questionIndex) => (field) => (value) => {
    const { exercise, questions } = this.props

    let newQuestions = [...questions]
    newQuestions[questionIndex][field] = value
    this.processChangeRequest(() => {
      this.changeExercise({ ...exercise }, newQuestions)
    })
  }

  setQuestionWithIndexParameter$$ = (questionIndex) => (parameter) => (value) => {
    const { exercise, questions } = this.props

    let newQuestions = [...questions]
    newQuestions[questionIndex]["parameters"][parameter] = value
    this.processChangeRequest(() => {
      this.changeExercise({ ...exercise }, newQuestions)
    })
  }

  deleteQuestionWithIndex$ = (questionIndex) => () => {
    const { exercise, questions } = this.props

    let newQuestions = [...questions]
    newQuestions.splice(questionIndex, 1)
    this.processChangeRequest(() => {
      this.changeExercise({ ...exercise }, newQuestions)
    })
  }

  setAnswerWithIndexFieldForQuestion$$$ = (questionIndex) => (answerIndex) => (field) => (
    value
  ) => {
    const { exercise, questions } = this.props

    let newQuestions = [...questions]
    newQuestions[questionIndex].answers[answerIndex][field] = value
    this.processChangeRequest(() => {
      this.changeExercise({ ...exercise }, newQuestions)
    })
  }

  deleteAnswerWithIndexForQuestion$$ = (questionIndex) => (answerIndex) => () => {
    const { exercise, questions } = this.props

    let newQuestions = [...questions]
    newQuestions[questionIndex].answers.splice(answerIndex, 1)
    this.processChangeRequest(() => {
      this.changeExercise({ ...exercise }, newQuestions)
    })
  }

  setAnswerWithIndexParameterForQuestion$$$ = (questionIndex) => (answerIndex) => (parameter) => (
    value
  ) => {
    const { exercise, questions } = this.props

    let newQuestions = [...questions]
    newQuestions[questionIndex].answers[answerIndex]["parameters"][parameter] = value
    this.processChangeRequest(() => {
      this.changeExercise({ ...exercise }, newQuestions)
    })
  }

  processChangeRequest = (callback) => {
    if (!this.state.dataSaving) {
      this.setState(
        {
          dataChanged: true,
        },
        () => {
          callback()
        }
      )
    }
  }

  addQuestion = () => {
    const { exercise, questions } = this.props
    const newQuestion = InfoFactory.getInfo(exercise["slug"]).newQuestion()

    this.processChangeRequest(() => {
      let newQuestions = [...questions, newQuestion]

      this.processChangeRequest(() => {
        this.changeExercise({ ...exercise }, newQuestions)
      })
    })
  }

  addAnswerForQuestion$ = (questionIndex) => () => {
    const { exercise, questions } = this.props
    const newAnswer = InfoFactory.getInfo(exercise["slug"]).newAnswer()

    this.processChangeRequest(() => {
      let newQuestions = [...questions]
      newQuestions[questionIndex].answers.push(newAnswer)

      this.processChangeRequest(() => {
        this.changeExercise({ ...exercise }, newQuestions)
      })
    })
  }

  selectExerciseById = (id) => {
    this.props.selectExerciseById(id)
  }

  loadExercise = () => {
    this.props.loadExercise()
  }

  changeExercise = (data, questions) => {
    this.props.changeExercise(data, questions)
  }

  saveExercise = () => {
    this.setState({
      dataChanged: false,
    })
    this.props.saveExercise()
  }
}

const mapStateToProps = (state) => {
  const { exercise, session, project } = state

  return {
    id: exercise.id,
    sessionId: session.currentSessionId,
    instanceId: project.currentProjectId,

    loaded: exercise.loaded,
    exercise: exercise.data,
    questions: exercise.questions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectExerciseById: (id) => {
      dispatch(ExerciseActions.selectExerciseById(id))
    },
    loadExercise: () => {
      dispatch(ExerciseActions.loadExercise())
    },
    changeExercise: (data, questions) => {
      dispatch(ExerciseActions.exerciseChanged({ data, questions }))
    },
    saveExercise: () => {
      dispatch(ExerciseActions.saveCurrentExercise())
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exercise))
