import DefaultInfo from "./_default"
import FIELD_TYPES from "../fieldTypes"

class MessageInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      {
        id: "header",
        description: "Nagłówek",
        placeholder: "Wpisz treść nagłówka",
        type: FIELD_TYPES.string,
        default: "",
      },
      {
        id: "content",
        description: "Treść",
        placeholder: "Wpisz treść głównej wiadomości",
        type: FIELD_TYPES.text,
        default: "",
      },
      {
        id: "buttonText",
        description: "Treść na przycisku",
        placeholder: "Tekst na przycisku przechodzenia dalej",
        type: FIELD_TYPES.string,
        default: "",
      },
      {
        id: "imageUrl",
        description: "Adres obrazka",
        placeholder: "Wpisz adres obrazka (/images/...)",
        type: FIELD_TYPES.string,
        default: "",
      },
      {
        id: "imageUrlVertical",
        description: "Adres obrazka (komórka)",
        placeholder: "Wpisz adres obrazka (/images/...)",
        type: FIELD_TYPES.string,
        default: "",
      },
      {
        id: "imageFull",
        description: "Zdjęcie na pełny ekran?",
        type: FIELD_TYPES.bool,
        default: false,
      },
    ],
  }

  static canAddAnswer = () => false
  static canAddQuestion = () => false
}

export default MessageInfo
