import DefaultInfo from "./_default"
import FIELD_TYPES from "../fieldTypes"
import { exerciseWithTime } from "./common/exercise"
import { answerWithCorrectIncorrectFeedback } from "./common/answer"

class MatchInfo extends DefaultInfo {
  static exercise = {
    parameters: [...exerciseWithTime],
  }

  static question = {
    ...DefaultInfo.question,
    parameters: [
      {
        id: "category1",
        subId: "name",
        description: "Nazwa pierwszej kategorii",
        placeholder: "Wpisz nazwę pierwszej kategorii",
        type: FIELD_TYPES.string,
        default: "",
        small: true,
      },
      {
        id: "category1",
        subId: "image",
        description: "Zdjęcie pierwszej kategorii",
        placeholder: "Wpisz adres zdjęcia pierwszej kategorii",
        type: FIELD_TYPES.string,
        default: "",
        small: true,
      },
      {
        id: "category2",
        subId: "name",
        description: "Nazwa drugiej kategorii",
        placeholder: "Wpisz nazwę drugiej kategorii",
        type: FIELD_TYPES.string,
        default: "",
        small: true,
      },
      {
        id: "category2",
        subId: "image",
        description: "Zdjęcie drugiej kategorii",
        placeholder: "Wpisz adres zdjęcia drugiej kategorii",
        type: FIELD_TYPES.string,
        default: "",
        small: true,
      },
      {
        id: "category3",
        subId: "name",
        description: "Nazwa pierwszej kategorii",
        placeholder: "Wpisz nazwę pierwszej kategorii",
        type: FIELD_TYPES.string,
        default: "",
        small: true,
      },
      {
        id: "category3",
        subId: "image",
        description: "Zdjęcie pierwszej kategorii",
        placeholder: "Wpisz adres zdjęcia pierwszej kategorii",
        type: FIELD_TYPES.string,
        default: "",
        small: true,
      },
      {
        id: "category4",
        subId: "name",
        description: "Nazwa drugiej kategorii",
        placeholder: "Wpisz nazwę drugiej kategorii",
        type: FIELD_TYPES.string,
        default: "",
        small: true,
      },
      {
        id: "category4",
        subId: "image",
        description: "Zdjęcie drugiej kategorii",
        placeholder: "Wpisz adres zdjęcia drugiej kategorii",
        type: FIELD_TYPES.string,
        default: "",
        small: true,
      },
    ],
  }

  static answer = {
    hasNoCorrectIncorrect: true,
    parameters: [
      {
        id: "category",
        type: FIELD_TYPES.radio,
        description: "Kategoria",
        default: 0,
        values: {
          1: "Pierwsza",
          2: "Druga",
          3: "Trzecia",
          4: "Czwarta",
        },
        inline: true,
        small: false,
      },
      ...answerWithCorrectIncorrectFeedback,
    ],
  }

  static newQuestion = () => ({
    content: "",
    parameters: {
      category1: {
        name: "",
        image: "",
      },
      category2: {
        name: "",
        image: "",
      },
      category3: {
        name: "",
        image: "",
      },
      category4: {
        name: "",
        image: "",
      },
    },
    answers: [MatchInfo.newAnswer()],
  })

  static newAnswer = () => ({
    content: "",
    correct: true,
    parameters: {
      category: 1,
      correctFeedback: "",
      incorrectFeedback: "",
    },
  })
}

export default MatchInfo
