import DefaultInfo from "./_default"
import { newExerciseWithTime } from "./common/exercise"
import FIELD_TYPES from "../fieldTypes"

class BubblesInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...newExerciseWithTime,
      {
        id: "floatSpeed",
        type: FIELD_TYPES.radio,
        description: "Prędkość wzlatywania",
        default: 0,
        values: {
          fast: "Szybko",
          medium: "Średnio",
          slow: "Wolno",
        },
        inline: true,
        small: false,
      },
    ],
    modules: [],
  }

  static question = {
    ...DefaultInfo.question,
    parameters: [
      {
        id: "feedback",
        type: FIELD_TYPES.text,
        description: "Informacja zwrotna",
        placeholder:
          "Wpisz informację, która pojawia się po zaznaczeniu wszystkich dobrych odpowiedzi",
        default: "",
      },
    ],
  }
}

export default BubblesInfo
